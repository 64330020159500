.clockerContainer {
  background-color: var(--background-color);
  min-height: 100vh;
  overflow: hidden;
}

.buttonBar {
  position: absolute;
  top: 10px;
  right: 10px;
}

.settingsModal {
  color: black;
  border: none;
  height: auto;
  width: auto;
  position: relative;
  display: inline-block;
  padding-left: 40px;
  min-width: 600px;
}
