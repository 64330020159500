@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Bebas Neue'), url('./BebasNeue.woff2') format('woff2');
}

@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  src: local('Courier Prime'), url('./CourierPrime.woff2') format('woff2');
}

@font-face {
  font-family: 'Comic Sans MS';
  font-style: normal;
  font-weight: 400;
  src: local('Comic Sans MS'), url('./ComicSansMS.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans'), url('./NotoSans.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url('./OpenSans.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url('./Roboto.woff2') format('woff2');
}

@font-face {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 400;
  src: local('Newsreader'), url('./Newsreader.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), url('./Montserrat.woff2') format('woff2');
}

@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: local('Dosis'), url('./Dosis.woff2') format('woff2');
}

@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: local('Anton'), url('./Anton.woff2') format('woff2');
}

@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  src: local('Orbitron'), url('./Orbitron.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Code Pro'), url('./SourceCodePro.woff2') format('woff2');
}

@font-face {
  font-family: 'Bungee Spice';
  font-style: normal;
  font-weight: 400;
  src: local('Bungee Spice'), url('./BungeeSpice.woff2') format('woff2');
}

@font-face {
  font-family: 'Nabla';
  font-style: normal;
  font-weight: 400;
  src: local('Nabla'), url('./Nabla.woff2') format('woff2');
}

@font-face {
  font-family: 'Press Start 2P';
  font-style: normal;
  font-weight: 400;
  src: local('Press Start 2P'), url('./PressStart2P.woff2') format('woff2');
}

@font-face {
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  src: local('Luckiest Guy'), url('./LuckiestGuy.woff2') format('woff2');
}

@font-face {
  font-family: 'Silkscreen';
  font-style: normal;
  font-weight: 400;
  src: local('Silkscreen'), url('./Silkscreen.woff2') format('woff2');
}

@font-face {
  font-family: 'Foldit';
  font-style: normal;
  font-weight: 400;
  src: local('Foldit'), url('./Foldit.woff2') format('woff2');
}
