.privacyBox {
  margin: 40px;
}

.heading_1 {
  font-family: Arial;
  font-size: 19px;
  color: #000000;
  margin-top: 20px;
}
.heading_2 {
  font-family: Arial;
  font-size: 17px;
  color: #000000;
}
.title {
  font-family: Arial;
  font-size: 26px;
  color: #000000;
}

.subtitle {
  font-family: Arial;
  font-size: 14px;
  color: #595959;
}

.body_text {
  font-family: Arial;
  font-size: 14px;
  color: #595959;
  padding-bottom: 10px;
  display: block;
}

.sub_head {
  font-family: Arial;
  font-size: 14px;
  color: #595959;
  padding-bottom: 10px;
  display: block;
  text-decoration-line: underline;
}

.link {
  font-family: Arial;
  font-size: 14px;
  color: #3030f1;
}
